@import url("https://fonts.googleapis.com/css2?family=GFS+Didot&family=Gruppo&family=Gudea:wght@400;700&display=swap");

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInNav {
  0% {
    opacity: 0;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.NavbarAnimation {
  animation: fadeInNav 2s;
}

.Animation {
  animation: fadeIn 3s;
  height: 90vh;
  width: 100vw;
}

.All {
  height: 100vh;
  width: 100vh;
  display: flex;
  background-color: #003342;
}

.Home {
  height: 100vh;
  width: 100vw;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  background-color: #003342;
}

.Home_Logo {
  height: 80vh;
  width: 80vw;
  justify-content: center;
}

.Slogan {
  display: block;
  font-family: "Gruppo", cursive;
  font-weight: 500;
  font-size: 5vh;
  color: white;
}

.Contact_Slogan {
  height: 30vh;
  width: 100vw;
  display: flex;
}

.Contact_Support {
  height: 30vh;
  width: 100vw;
  display: flex;
  border-top: solid 1px #003342;
}

.Contact_Empty {
  height: 10vh;
}

.Contact_Text {
  display: inline-block;
  width: 80vw;
  align-self: flex-end;
  margin-left: 10px;
  margin-bottom: 10px;
  font-family: "GFS Didot", serif;
  font-size: 40px;
  color: #003342;
  letter-spacing: 0.3rem;
}

.Contact {
  animation: fadeIn 1s;
}

.Mission {
  margin-left: 15vw;
  margin-right: 15vw;
}

.Mission_Slogan_Quote_Left {
  margin-top: 5vh;
}

.Mission_Slogan_Quote_Right {
  margin-top: 5vh;
  margin-left: 70vw;
}

.Mission_Slogan_Text_Div {
  margin-top: 5vh;
  margin-bottom: 5vh;
  width: 70vw;
  text-align: center;
}

.Mission_Slogan_Text {
  font-family: "GFS Didot", serif;
  font-size: 60px;
  letter-spacing: 0.8rem;
  color: #003342;
}

.Mission_WindowNet {
  margin-left: 25vw;
  margin-right: 25vw;
  text-align: center;
  width: 50vw;
}

.Divider {
  margin-left: 10vw;
  margin-right: 10vw;
  width: 30vw;
  border-top: solid 1px #003342;
  margin-top: 10vh;
  margin-bottom: 5vh;
}

.Divider2 {
  margin-left: 35vw;
  margin-right: 35vw;
  width: 30vw;
  border-top: solid 1px #003342;
  margin-top: 10vh;
  margin-bottom: 5vh;
}

.Mission_WindowNet_Title {
  font-family: "Gudea", serif;
  font-size: 36px;
  font-weight: 700;
  color: #003342;
  margin-bottom: 5vh;
}

.Mission_WindowNet_Motto_Title {
  font-family: "Gruppo", cursive;
  font-size: 36px;
  font-weight: 700;
  color: #003342;
  margin-bottom: 5vh;
  margin-right: 0.5vw;
}
.Mission_WindowNet_Motto_Text {
  font-family: "Gruppo", cursive;
  font-size: 20px;
  font-weight: 500;
  color: #003342;
  margin-bottom: 5vh;
}
.Mission_Empty3 {
  height: 1vh;
}

.Mission_Empty {
  height: 3vh;
}

.Mission_WindowNet_Text {
  font-family: "Gruppo", cursive;
  font-size: 20px;
  color: #003342;
}

.Mission_Team {
  margin-left: 25vw;
  margin-right: 25vw;
  text-align: center;
  width: 50vw;
}

.Mission_Animation {
  animation: fadeIn 1s;
}

.Mission_Team_Image {
  margin-top: 5vh;
  margin-bottom: 5vh;
  width: 40vw;
}

.Mission_Aim {
  margin-left: 25vw;
  margin-right: 25vw;
  text-align: center;
  width: 50vw;
}

.Mission_Column {
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
}

.Column {
  flex: 30%;
  margin-left: 5vw;
  margin-right: 5vw;
  text-align: center;
}

.Mission_Benefits {
  margin-top: 5vh;
  margin-bottom: 5vh;
  font-family: "Gruppo", cursive;
  font-size: 24px;
  font-weight: 700;
  color: #003342;
  text-decoration: underline;
}

.Mission_Empty2 {
  margin-top: 5vh;
}

.Statistics {
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 5vh;
}

.Statistics_Title {
  font-family: "GFS Didot", serif;
  font-size: 100px;
  font-weight: 700;
  height: 90px;
  display: block;
  letter-spacing: 0.8rem;
  color: #145274;
  margin-bottom: 0;
  display: flex;
}

.Statistics_Stats {
  min-height: 800px;
  width: 90vw;
  background-color: #145274;
  border-radius: 10px;
}

.Statistics_Stat {
  min-height: 200px;
  width: 84%;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-bottom: 50px;
}

.Statistics_Major {
  height: 200px;
  font-family: "Gruppo", cursive;
  font-size: 200px;
  font-weight: 500;
  color: white;
}

.Statistics_Minor {
  margin-left: 1vw;
  height: 200px;
  font-family: "Gruppo", cursive;
  font-size: 60px;
  color: white;
}

.Testimonials_Animation {
  animation: fadeIn 1s;
}

.Info_Image {
  width: 100vw;
  animation: fadeIn 1s;
}

.Testimonials_Image {
  width: 100%;
  /* margin-bottom: 5vh; */
}

.Product {
  animation: fadeIn 1s;
}

.Product_Intro {
  margin-top: 10vh;
  margin-left: 25vw;
  margin-right: 25vw;
  text-align: center;
  width: 50vw;
}

.Product_Title {
  font-family: "Gudea", serif;
  font-size: 36px;
  font-weight: 700;
  color: #003342;
  margin-bottom: 5vh;
}

.Product_Column {
  margin-top: 5vh;
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
}

.Product_Column1 {
  flex: 50%;
  margin-left: 5vw;
  margin-right: 5vw;
  text-align: center;
  width: 50%;
  height: 40vh;
}

.Product_Column2 {
  flex: 30%;
  margin-left: 5vw;
  margin-right: 5vw;
  text-align: center;
  /* display: flex; */
}

.Product_Col_Text {
  font-family: "Gruppo", cursive;
  font-size: 20px;
  color: #003342;
  font-weight: 600;
  height: 30vh;
  justify-content: center;
  align-items: center;
}

.Product_Model {
  margin-top: 10vh;
  margin-left: 25vw;
  margin-right: 25vw;
  text-align: center;
  width: 50vw;
}

.Product_Column_Struct {
  margin-top: 5vh;
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
}

.Product_Column3 {
  flex: 35%;
  margin-left: 5vw;
  margin-right: 5vw;
  text-align: center;
  display: flex;
}

.Product_Model_Title {
  margin-top: 5vh;
  margin-bottom: 5vh;
  font-family: "Gruppo", cursive;
  font-size: 24px;
  font-weight: 700;
  color: #003342;
  text-decoration: underline;
}

.Product_Empty {
  margin-top: 2vh;
}

.Product_Model_Price {
  font-family: "Gruppo", cursive;
  font-size: 18px;
  color: #003342;
  font-weight: 600;
}

.Product_Model_Description {
  font-family: "Gruppo", cursive;
  font-size: 18px;
  color: #003342;
  font-weight: 400;
}

.Product_Market {
  margin-top: 10vh;
  margin-left: 25vw;
  margin-right: 25vw;
  text-align: center;
  width: 50vw;
}

.Product_Image {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.Temp {
  margin-bottom: 10vh;
}

.Contact_Button {
  margin-top: 22vh;
  height: 5vh;
  width: 7vw;
  border-radius: 10px;
  background-color: #ffffff;
  border: 2px solid #003342;
  font-family: "GFS Didot", serif;
  color: #003342;
}

.Contact_Since {
  margin-top: 27vh;
  font-family: "GFS Didot", serif;
  font-size: 16px;
  color: #003342;
}

button:active {
  background-color: #003342;
  color: #ffffff;
}

a {
  color: #003342;
  font-style: normal;
  text-decoration: none;
  font-family: "GFS Didot", serif;
  font-size: 14px;
}

.Mission_Motto {
  margin-left: 25vw;
  margin-right: 25vw;
  text-align: center;
}

.Product_video_style {
  color: #003342;
  font-style: normal;
  text-decoration: none;
  font-family: "Gruppo", serif;
  font-size: 20px;
  margin-left: 1vh;
}
