@import url("https://fonts.googleapis.com/css2?family=Gudea:wght@400;700&display=swap");

.Nav {
  background: #003342;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  z-index: 12;
}

.Bar {
  display: none;
  color: #ffffff;
}

.Title {
  width: 50%;
  display: flex;
  align-items: flex-start;
}

.NavMenu {
  display: flex;
  align-items: flex-end;
  margin-right: 3vw;
}

.Logo {
  height: 80px;
  margin-right: 10px;
}
